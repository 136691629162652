@media (max-width: 1023px) {
  .friendly-bets {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
    max-width: 1280px;
    width: 98vw;
    color: #004638;
    font-weight: 700;
  }
  
  .friendly-bets-top {
    margin-top: 4vmin;
    width: 100vw;
    font-size: 6vmin;
    font-weight: 800;
    background: linear-gradient(180deg,#f6f6f6 0%,#f6f6f6 30%,#d8f537 31%,#d8f537 100%);
  }
  
  .friendly-bets-title {
    font-size: 6vmin;
    text-align: left;
    background: linear-gradient(180deg,#f6f6f6 0%,#f6f6f6 30%,#d8f537 31%,#d8f537 100%);
    width: fit-content;
  }
  
  .friendly-bets-description {
    margin-top: 5vmin;
    text-align: left;
    color: #82bf00;
    font-size: 3vmin;
  }
  
  .friendly-bets-disclaimer {
    text-align: left;
    font-size: 2.5vmin;
    margin-top: 2vmin;
  }
  
  .friendly-bets-all-link {
    margin-top: 5vmin;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: fit-content;
  }
  
  .friendly-bets-hover-animation {
    width: fit-content;
    cursor: pointer;
    border:none;
    position: relative;
    transition: all 0.3s ease-in-out;
    margin-bottom: 4vmin;
    color: #004638;
  }
  
  .friendly-bets-hover-animation::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 1vmin;
    left: 50%;
    bottom: 4;
    background:#82bf00;
    transition: all ease-in-out .15s;
  }
  
  .friendly-bets-hover-animation:hover {
    border: none;
  }
  
  .friendly-bets-hover-animation:hover::after {
    width: 100%;
    left: 0;
  }
  
  .friendly-bets-container {
    display: flex;
    width: 98vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4vmin;
  }
  
  .friendly-bets-left {
    width: 90%;
  }
  
  .friendly-bets-right {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  
  .friendly-bets-screenshot-title {
    font-size: 4vmin;
    text-align: left;
    background: linear-gradient(180deg,#f6f6f6 0%,#f6f6f6 30%,#d8f537 31%,#d8f537 100%);
    width: fit-content;
  }

}