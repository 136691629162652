.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #d8f537 transparent #d8f537 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.spinner-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}