@media (min-width: 1024px) {

  .LoginPage {
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .Login {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .Loading {
    height: 10vh;
    margin-left: 2vw;
  }
  
  .EmailForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .EmailInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .Email {
    background-color: #f6f6f6;
    border: 3px solid #004638;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    height: 5vh;
    width: 20vw;
    font-size: medium;
  }

  .Password {
    background-color: #f6f6f6;
    border: 3px solid #004638;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    height: 5vh;
    width: 20vw;
    font-size: medium;
    margin-bottom: 16px;
  }
  
  
  h6 {
    color: red;
    margin: 1px;
  }
  
  .SubmitEmail {
    align-self: flex-end;
    background-color: #004638;
    height: 5vh;
    width: 15vh;
    border-radius: 10px;
    color: #f6f6f6;
    border: none;
    font-family: Gilroy-Medium,Helvetica,Arial,sans-serif;
    --fa-style-family-classic: "Font Awesome 6 Free";
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Free";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  .SubmitEmail:hover {
    cursor: pointer;
    background-color: #82bf00;
  }

  .Formik {
    align-self: flex-start;
  }

  .label {
    font-family: Gilroy-Medium,Helvetica,Arial,sans-serif;
    --fa-style-family-classic: "Font Awesome 6 Free";
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Free";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #004638;
    font-weight: bold;
    margin-top: 20px;
  }


  .WeatherBoxTemp {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    border-radius: 10px;
    padding: 8px;
  }

  .WeatherBoxHumi {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    border-radius: 10px;
    padding: 8px;
  }

  .TopWeatherBox {
    margin-bottom: 2px;
  }

  .BottomWeatherBox {
    display: flex;
    width: 20vw;
    justify-content: space-between;
  }

  .LeftWeatherBox {
    color: #f6f6f6;
    background-color: #004638;
    border-radius: 10px;
    padding: 8px;
    width: 200px;
  }

  .RightWeatherBox {
    color: #f6f6f6;
    background-color: #82bf00;
    border-radius: 10px;
    padding: 8px;
    width: 200px;                               
  }

  .LoginAcState {
    font-size: 16px;
    margin-top: 32px;
    width: 12vw;
    border-radius: 10px;
    padding: 2vh;
    background-color: #004638;
    color: #f6f6f6;
  }

  .OptionSelect {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 16px;
    margin-top: -64px;
  }

  .DateSelect {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .ButtonSelect {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: larger;
  }

}