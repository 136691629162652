@media (max-width: 1023px) {
  .experiences {
    margin: 1vmin 3vmin 5vmin 0vmin;
    min-height: 90vh;
    width: 98vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .experience-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .experience-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 98vw;
    font-size: 3vmin;
  }
  
  .search-bar {
    background-color: #f6f6f6;
    color: #004638;
    border: 0.5vmin solid #004638;
    border-radius: 2vmin;
    font-size: 3vmin;
    padding: 2vmin;
    margin-left: 5vmin;
    margin-bottom: 5vmin;
    width: 70vw;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #004638;
    font-family: Arial, Helvetica, sans-serif;
    opacity: 0.5; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #004638;
    font-family: Arial, Helvetica, sans-serif;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #004638;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  
  .individual-experience {
    width: 90vw;
    border-style: dotted none none none;
    border-color: #004638;
    color: #004638;
    margin: 0vmin 0vmin 1vmin 5vmin;
    padding: 4vmin 2vmin 4vmin 1vmin;
    box-shadow: 0px 0px black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
    -webkit-animation: individualExp 0.25s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: individualExp 0.25s; /* Firefox < 16 */
     -ms-animation: individualExp 0.25s; /* Internet Explorer */
      -o-animation: individualExp 0.25s; /* Opera < 12.1 */
      animation: individualExp 0.25s;
  }
  
  
  .individual-experience:hover {
    cursor: pointer;
    background-color: #004638;
    color: #f6f6f6;
    margin: 0vmin 0vmin 1vmin 4vmin;
    box-shadow: 1vmin 1vmin black;
    -webkit-animation: individualExpHover 0.25s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: individualExpHover 0.25s; /* Firefox < 16 */
     -ms-animation: individualExpHover 0.25s; /* Internet Explorer */
      -o-animation: individualExpHover 0.25s; /* Opera < 12.1 */
      animation: individualExpHover 0.25s;
  }
  
  .ie-top {
    display: flex;
    align-items: center;
    margin-bottom: 2vmin;
  }
  
  .ie-middle {
    font-size: 3vmin;
    font-weight: 500;
    text-align: start;
    margin-bottom: 2vmin;
    color: #82bf00;
  }
  
  .ie-bottom {
    display: flex;
  }
  
  .ie-institution {
    font-size: 4vmin;
    margin-right: 1vmin;
  }
  
  .ie-date {
    font-size: 1.5vmin;
    padding: 0vmin 0.5vmin 0vmin 0.5vmin;
    border: 1px solid #004638;
    border-radius: 3vmin;
    margin-right: 1vmin;
  }
  
  .ie-languages {
    font-size: 2.2vmin;
    display: flex;
    flex-wrap: wrap;
    font-weight: 700;
  }
  
  .languages {
    margin-right: 1vmin;
    border-left: 1px solid #004638;
    padding-left: 1vmin;
    margin-bottom: 1vmin;
  } 

  @keyframes individualExp {
    from {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
  }
  /* Firefox < 16 */
  @-moz-keyframes individualExp {
    from {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
  }
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes individualExp {
    from {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
  }
  /* Internet Explorer */
  @-ms-keyframes individualExp {
    from {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
  }
  /* Opera < 12.1 */
  @-o-keyframes individualExp {
    from {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
  }

  @keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
  }
  /* Firefox < 16 */
  @-moz-keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
  }
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
  }
  /* Internet Explorer */
  @-ms-keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
  }
  /* Opera < 12.1 */
  @-o-keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 1vmin 5vmin; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 1vmin 1vmin black; margin: 0px 0px 1vmin 4vmin; background-color: #004638; color: #f6f6f6;}
  }
}