@media (min-width: 1024px) {
  .displayed-experience {
    margin-bottom: 32px;
    max-width: 1280px;
    width: 98vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 90vh;
  }
  
  
  .single-experience {
    background-color: #d8f537;
    width: 90%;
    color: #004638;
    margin: 0px 24px 24px 24px;
    padding: 24px 0px 24px 0px;
    font-size: 24px;
    box-shadow: 24px 24px black;
  }
  
  .title-experience {
    margin-top: 32px;
    margin-bottom: 32px;
    background: linear-gradient(180deg,#f6f6f6 0%,#f6f6f6 30%,#d8f537 31%,#d8f537 100%);
    font-size: 32px;
    font-weight: 800;
  }
  
  
  .experience-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0px 0px 0px 40px;
  }
  
  .exp-line {
    text-align: left;
  }
  
  .codeworks-link {
    border: 4px solid #004638;
    box-shadow: 4px 4px #004638;
    color: #004638;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    padding: 8px;
  }
  
  .codeworks-link:hover {
    cursor: pointer;
    background-color:#004638;
    color: #d8f537;
  }
  
  .codeworks-link:active {
    margin-left: 4px;
    margin-top: 4px;
    box-shadow: 0px 0px #004638;
  }
}