@media (max-width: 1023px) {

  .UserPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  h1 {
    color: #f6f6f6;
  }
  
  .namefield {
    margin-top: 100px;
    background-color: #004638;
    width: 80vw;
    border-radius: 10px;
  }
  
  .DisconnectButton {
    margin-top: 100px;
    background-color: #004638;
    color: #f6f6f6;
    border: none;
    padding: 3vh;
    border-radius: 10px;
    font-size: larger;
  }
  
  .DisconnectButton:hover {
    cursor: pointer;
    background-color: #82bf00;
  }
  
  .DisconnectButton:active {
    background-color: #82bf00;
    cursor: pointer;
  }
  
}