@media (max-width: 1023px) {

  .telecom {
    height: 100vh;
  }

  .telecom-top {
    margin-top: 4vmin;
    width: 100vw;
    font-size: 6vmin;
    font-weight: 800;
    background: linear-gradient(180deg,#f6f6f6 0%,#f6f6f6 30%,#d8f537 31%,#d8f537 100%);
  }
  
}