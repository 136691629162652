@media (min-width: 1024px) {
  .gpmm {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
    max-width: 1280px;
    width: 98vw;
    color: #004638;
    font-weight: 700;
  }
  
  .gpmm-title {
    font-size: 48px;
    text-align: left;
    background: linear-gradient(180deg,#f6f6f6 0%,#f6f6f6 30%,#d8f537 31%,#d8f537 100%);
    width: fit-content;
  }
  
  .gpmm-description {
    margin-top: 40px;
    text-align: left;
    color: #82bf00;
    font-size: 24px;
  }
  
  .gpmm-disclaimer {
    text-align: left;
    margin-top: 16px;
  }
  
  .gpmm-all-link {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: fit-content;
  }
  
  .gpmm-hover-animation {
    cursor: pointer;
    width: fit-content;
    border:none;
    position: relative;
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
    color: #004638;
  }
  
  .gpmm-hover-animation::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 8px;
    left: 50%;
    bottom: 4;
    background:#82bf00;
    transition: all ease-in-out .3s;
  }
  
  .gpmm-hover-animation:hover {
    border: none;
  }
  
  .gpmm-hover-animation:hover::after {
    width: 100%;
    left: 0;
  }
  
  .gpmm-container {
    display: flex;
    margin-top: 32px;
  }
  
  .gpmm-left {
    width: 50%;
  }
  
  .gpmm-right {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  
  .gpmm-screenshot-title {
    font-size: 32px;
    text-align: left;
    background: linear-gradient(180deg,#f6f6f6 0%,#f6f6f6 30%,#d8f537 31%,#d8f537 100%);
    width: fit-content;
  }
}